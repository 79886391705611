<template>
  <main>
    <PageTitle>{{ contentData.title[0].value }}</PageTitle>

    <ImageTriptych 
      :image="titleImage" 
      :title="contentData.field_titel[0].value" 
      :blurb="contentData.field_zusammenfassung[0].value" 
    />

    <section class="z-depth-1" ref="bannerContainer">
      <div 
        class="banner-image" 
        :style="{backgroundImage: `url(${contentData.field_beispiel_bild[0].url})`}" 
        ref="banner"
      ></div>
    </section>

    <section class="planning__section">
      <div class="planning__body-container" ref="planningBodyContainer">
        <p
          class="planning__body text--z-depth-1"
          v-html="contentData.field_planung_koerpertext[0].value"
          ref="planningBody"
        ></p>
      </div>

      <div
        class="planning__img-container planning__img1-container"
        ref="img1container"
      >
        <div
          class="z-depth-1"
          :style="{ backgroundImage: `url(${contentData.field_planung_bilder[0].url})` }"
          ref="img1"
        ></div>
      </div>

      <div class="planning__title text--z-depth-1" ref="planningTitleContainer">
        <h3 ref="planningTitle">{{ contentData.field_planung_titel[0].value }}</h3>
      </div>

      <div
        class="planning__img-container planning__img2-container"
        ref="img2container"
      >
        <div
          class="z-depth-1"
          :style="{ backgroundImage: `url(${contentData.field_planung_bilder[1].url})` }"
          ref="img2"
        ></div>
      </div>

      <div
        class="planning__img-container planning__img3-container"
        ref="img3container"
      >
        <div
          class="z-depth-1"
          :style="{ backgroundImage: `url(${contentData.field_planung_bilder[2].url})` }"
          ref="img3"
        ></div>
      </div>
    </section>
  </main>
</template>

<script>
import { httpMixin } from "@/mixins/httpMixin";
import ScrollMagic from "scrollmagic";
import PageTitle from "@/components/PageTitle.vue";
import ImageTriptych from "@/components/ImageTriptych";
import { incrementComponentsLoading } from "@/events";

export default {
  mixins: [httpMixin],

  components: { PageTitle, ImageTriptych },

  data: function() {
    return {
      animationController: new ScrollMagic.Controller(),
      titleImage: require("@/media/tech_title.png"),
      contentData: {
        title: [{value: ""}],
        field_titel: [{value: ""}],
        field_zusammenfassung: [{value: ""}],
        field_beispiel_bild: [{url: ""}],
        field_planung_titel: [{value: ""}],
        field_planung_koerpertext: [{value: ""}],
        field_planung_bilder: [{url: ""}, {url: ""}, {url: ""}]
      }
    };
  },
  
  created: function() {
    const self = this;
    this.componentsLoading++;
    this.fetchFromAPI("content/haustechnik")
      .then(content => {
        self.$set(this, "contentData", content[0]);
        self.componentsLoading--;
      })
      .catch(self.handleError);
  },

  mounted: function() {
    this.slideEnter(this.$refs.bannerContainer, this.$refs.banner);
    this.slideEnter(
      this.$refs.planningBodyContainer,
      this.$refs.planningBody,
      50
    );
    this.slideEnter(
      this.$refs.planningTitleContainer,
      this.$refs.planningTitle
    );
    this.slideEnter(this.$refs.img1container, this.$refs.img1);
    this.slideEnter(this.$refs.img2container, this.$refs.img2);
    this.slideEnter(this.$refs.img3container, this.$refs.img3);
  },

  methods: {
    slideEnter(triggerElement, target, offsetPercent = 0, triggerHook = 0.8) {
      target.classList.add("slide-enters");
      new ScrollMagic.Scene({
        triggerElement,
        offset: (triggerElement.scrollHeight * offsetPercent) / 100,
        triggerHook,
        reverse: false
      })
        .setClassToggle(target, "slide-enters--entered")
        .addTo(this.animationController);
    }
  },

  beforeRouteLeave(to, from, leave) {
    this.$emit(incrementComponentsLoading);
    leave();
  }
};
</script>

<style scoped>
.slide-enters {
  transform: translateY(80px);
  opacity: 0;
  transition: all 0.7s;
}

.slide-enters--entered {
  transform: none;
  opacity: 1;
}

section {
  background: none;
  margin: 40px 0;
}

.banner-image {
  background-size: cover;
  height: calc((100vw / 21) * 9);
  background-position: center;
  margin: 0 -24px;
}

.planning__section {
  display: grid;
}

.planning__body-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  grid-row: 3 / 4;
}

.planning__body {
  text-align: left;
}

.planning__img-container > div {
  background-position: center;
  background-size: cover;
}

.planning__img1-container > div {
  height: 42.86vw;
}

.planning__title {
  text-align: left;
  grid-row: 1 / 2;
}

.planning__title > * {
  text-transform: uppercase;
}

.planning__img2-container > div {
  height: 42.86vw;
}

.planning__img3-container {
  margin-top: 16px;
}

.planning__img3-container > div {
  height: 42.86vw;
}

.planning__img3-container > div:not(.slide-enters--entered) {
  transform: translateY(60px);
}

@media screen and (min-width: 768px) {
  .banner-image {
    grid-column: 1 / 4;
    margin: 0;
  }

  .planning__section {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .planning__title {
    grid-column: 1 / 4;
    grid-row: 3 / 4;
  }

  .planning__body-container {
    grid-row: 1 / 2;
  }

  .planning__img1-container {
    grid-column: 2 / 4;
  }

  .planning__img1-container > div {
    height: 28.6vw;
  }

  .planning__img2-container {
    grid-column: 1 / 3;
    grid-row: 7 / 9;
  }

  .planning__img2-container > div {
    height: 28.6vw;
    max-height: 309px;
  }

  .planning__img3-container {
    grid-column: 2 / 4;
    grid-row: 8 / 10;
    height: 21.4vw;
    margin-left: 16.7vw;
    margin-top: 4vw;
  }

  .planning__img3-container > div {
    height: 21.4vw;
  }
}

@media screen and (min-width: 1025px) {
  .banner-image {
    height: calc((75vw / 21) * 9);
    max-height: 463px;
  }

  .planning__img1-container > div {
    height: 21.4vw;
    max-height: 309px;
  }

  .planning__img2-container > div {
    height: 21.42vw;
    max-height: 309px;
  }

  .planning__img3-container {
    height: 16.07vw;
    max-height: 231px;
    margin-left: 12.5vw;
  }

  .planning__img3-container > div {
    height: 16.07vw;
    max-height: 231px;
  }
}
</style>
